import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ModalManagerService, SelectPicture, SelectPictureServiceInterface } from 'common';

@Injectable({
  providedIn: 'root'
})
export class SelectPictureService implements SelectPictureServiceInterface {
  constructor(private modalManagerService: ModalManagerService) {
  }

  async selectPicture(param: SelectPicture) {
    const sbj = new Subject();
    sbj.asObservable().pipe(
      param.pipe
    ).subscribe();
    // this.modalManagerService.displayModal('SelectPicture', {
    //   component: SelectPictureModalComponent,
    //   cssClass: 'big-modal',
    //   componentProps: {
    //     subjectResult: sbj,
    //     pictureToUpdate: param.pictureToUpdate,
    //     sourceName: param.sourceName,
    //     flagIconName: param.flagIconName,
    //     pictureHeight: param.height,
    //     pictureWidth: param.width,
    //     withFlag: param.withFlag,
    //     type: param.type,
    //   }
    // });
  }
}
