import { Injectable } from "@angular/core";
import { InstructionServiceInterface } from "common/service/instruction.service.interface";
import { Observable, of } from "rxjs";
import { InstructionDto } from "common/api/models/instruction-dto";

@Injectable({
  providedIn: 'root'
})
export class InstructionService implements InstructionServiceInterface {

  getById(id: string): Observable<InstructionDto> {
    return undefined;
  }

  getInstructionUpgraded(instruction: InstructionDto): Observable<InstructionDto> {
    return of(instruction);
  }

}
