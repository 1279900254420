import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';
import { UserService } from '../service/user.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService, ModalHelperService } from 'common';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService,
              private userService: UserService,
              private translateService: TranslateService,
              private modalHelperService: ModalHelperService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add authorization header
    return this.authService.getJwt()
      .pipe(
        mergeMap((idToken) => {
          req = req.clone({
            setHeaders: {
              // eslint-disable-next-line @typescript-eslint/naming-convention
              Authorization: idToken ? 'Bearer ' + idToken : '',
            }
          });
          return next.handle(req).pipe(
            tap(x => x, err => {
              // convert error to object if required
              if (err instanceof HttpErrorResponse) {
                this.managerError(err);
              }
              if (typeof err.error === 'string') {
                err.error = JSON.parse(err.error);
              }
              if (err.error?.message) {
                err.message = err.error.message;
              }
              console.info(`Error performing request, status code = ${err.status}`);
            })
          );
        })
      );
  }

  private managerError(error: HttpErrorResponse) {
    if (error.status === 401) {
      // unauthorized : log out the user
      this.authService.signOut().subscribe();
    } else if (error.status === 417) {
      // expectation failed
      if (typeof error?.error === 'string' || error?.error instanceof String) {
        const err = JSON.parse(error.error as string);
        this.modalHelperService.displayToaster(err.message, 4000);
      } else if (error?.error?.message) {
        this.modalHelperService.displayToaster(error.error.message, 4000);
      }
    } else if (error.status >= 500) {
      this.modalHelperService.displayToaster(this.translateService.instant('network.unknownError'), 4000);
    }
  }
}
