import { Injectable } from '@angular/core';
import { AuthService, StorageService, UserDto, UserServiceInterface } from 'common';
import { map, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class UserService implements UserServiceInterface {

  constructor(private authService: AuthService,
              private storageService: StorageService,
  ) {
  }

  // Override
  refreshUser(): Observable<void> {
    throw new Error('Method not implemented.');
  }

  // Override
  hasMyself(): Observable<boolean> {
    return this.getMyself()
      .pipe(map(user => !!user));
  }


  // Override
  saveMyself(user: UserDto): Observable<void> {
    this.saveUser(user);
    return of(void 0);
  }

  // Override
  saveUser(user: UserDto): Observable<void> {
    this.storageService.storeItem('user', JSON.stringify(user));
    return of(void 0);
  }

  // Override
  getMyself(): Observable<UserDto> {
    const user = this.storageService.getItem('user');
    return of(JSON.parse(user) as UserDto);
  }

  // Override
  getMyselfUserName(): Observable<string> {
    return this.getMyself()
      .pipe(
        map((user: UserDto) => user.firstName + ' ' + user.lastName)
      );
  }

  clearMyself(): Observable<void> {
    this.storageService.cleanAll();
    return of(void 0);
  }

  logout(_: boolean) {
    this.storageService.cleanAll();
    this.authService.signOut()
      .pipe(
        tap(() => window.location.reload())
      )
      .subscribe();
  }
}
