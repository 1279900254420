import { Injectable } from '@angular/core';
import {
  ResourceApiService,
  ResourceListDto,
  ResourceListItemDto,
  ResourceServiceInterface,
} from 'common';
import { Observable, of, Subject } from 'rxjs';
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class ResourceService implements ResourceServiceInterface {
  private static serverCalled = false;
  private static waitingObservableMap: Map<Subject<ResourceListDto>, string> = new Map();
  private static resources: Map<string, ResourceListDto> | undefined;

  constructor(private resourceApiService: ResourceApiService) {
  }

  getItems(listReference: string, prefix?: string): Observable<ResourceListItemDto[]> {
    return of([{
      id: '1',
      reference: 'electricity',
      name: 'Electricité',
      illustrationPath: {storageName: 'global/resources/risks/Electricite.png'},
    } as ResourceListItemDto, {
      id: '2',
      reference: 'gaz',
      name: 'Gaz',
    } as ResourceListItemDto]);
  }

  findResourceItemByListNameAndName = (resourceListReference: string, resourceReference: string): Observable<ResourceListItemDto | undefined> => {
    return this.getItems(resourceListReference)
      .pipe(
        map((items) => items[0])
      );
  }

  forceReload() {
    ResourceService.resources = undefined;
    ResourceService.serverCalled = false;
  }
}
