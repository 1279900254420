import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { OrganDto, OrganServiceInterface, OrganType, StateEnumDto } from "common";

@Injectable({
  providedIn: 'root'
})
export class OrganService implements OrganServiceInterface {
  organs: Map<string, OrganDto> = new Map<string, OrganDto>();

  constructor() {
    this.buildData();
  }

  getById(id: string, forceServerCall: boolean): Observable<OrganDto> {
    return of(this.organs.get(id));
  }

  buildData(): void {
    this.organs.set('aze', {
      id: 'aze',
      identifier: 'aze',
      reference: 'aze',
      version: 1,
      obsolete: false,
      archived: false,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      type: 'OrganDto',
      state: StateEnumDto.Approved,
      lastVersion: true,
      organType: OrganType.OrganOfSeparation,
      designation: 'Disjoncteur principal',
      landmark: 'AA-01',
      separatorType: 'Disjoncteur',
      illustration: {
        storageName: 'disjoncteur.png',
        flag: {
          y: '50%',
          x: '50%',
        }
      },
      condemnation: {
        sentencingPosition: 'Fermé',
        restitutionPosition: 'Ouvert',
        lockable: true,
        lockerType: 'Standar 4mm',
        consignmentAccessories: ['Boulon double', 'clef verte'],
        equipments: ['Veste rouge', 'bonnet père noël'],
        risks: ['Risque de choc électrique'],
        verifications: [{
          name: 'Vérification de la présence de la clé',
          verifyImmediately: true,
          illustration: {
            storageName: 'key.png',
          }
        }]
      }
    } as OrganDto);
  }

}
