import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { IsNotLoggedGuard } from './guard/is-not-logged.guard';
import { IsLoggedGuard } from './guard/is-logged.guard';

const routes: Routes = [
  {
    path: 'authentication',
    canActivate: [IsNotLoggedGuard],
    loadChildren: () => import('./module/authentication/authentication.module').then(m => m.AuthenticationPageModule)
  },
  {
    path: 'app',
    canActivate: [IsLoggedGuard],
    loadChildren: () => import('./module/app/app.module').then(m => m.AppPageModule)
  },
  {path: '**', redirectTo: 'authentication', pathMatch: 'full'},
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
