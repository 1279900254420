import { Component, Input } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import {
  AbstractForm,
  ModalHelperService,
  SelectItem,
  StorageService,
  TrackingService
} from 'common';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CreateFactoryDto } from "../../../api/models/create-factory-dto";
import { RoleDto } from "../../../api/models/role-dto";

@Component({
  selector: 'app-from-backoffice-form',
  templateUrl: 'create-factory-form.component.html',
  styleUrls: [],
})
export class CreateFactoryFormComponent extends AbstractForm<CreateFactoryDto> {
  anyChanges = false;
  create = true;
  @Input() externalSubmit?: Observable<void> | null = null;
  // @ts-ignore
  @Input() focusObs?: Observable<void>;
  updateMode = false;
  emailValidationMessages = new Map<string, string>([
    ['required', 'form.validation.email.required'],
    ['email', 'form.validation.email.format'],
  ]);
  roles: SelectItem[];
  itemsForcedChecked = [RoleDto.Administrator, RoleDto.User];

  constructor(formBuilder: FormBuilder,
              router: Router,
              modalHelperService: ModalHelperService,
              translateService: TranslateService,
              storageService: StorageService,
              trackingService: TrackingService,
  ) {
    super(formBuilder, router, modalHelperService, translateService, storageService, trackingService);
    this.roles = Object.keys(RoleDto)
      .map(key => ({
        name: translateService.instant('roles.' + RoleDto[key].toLowerCase()),
        value: RoleDto[key],
      } as SelectItem));
  }

  ngOnInit() {
    super.ngOnInit();
  }

  resetForm() {
    super.resetForm();
    this.anyChanges = false;
  }

  requestSuccess() {
    this.resetForm();
    this.modalHelperService.displayToaster(this.translateService.instant('form.user.success'), 10000);
  }

  buildForm(): any {
    this.form = this.formBuilder.group({
      adminEmail: ['', [Validators.required, Validators.email]],
      // lang: ['fr', [Validators.required]],
      name: ['', [Validators.required]],
      address: ['', []],
      roles: [[], Validators.required],
      // logo: [undefined]
    });
  }

  initData() {
    super.initData();
    if (this.externalSubmit) {
      this.externalSubmit.subscribe(() => this.submitForm())
    }
    this.form?.valueChanges
      .subscribe(() => {
        this.anyChanges = true;
      });
    this.anyChanges = false;
  }
}
